/* eslint-disable max-len */
export const projects = [
  {
    title: 'Synoptic Intelligence Network',
    description:
      'Community-driven intelligence network',
    position: 'Founding Engineer',
    timeActivity: 'September 2023 — Present',
    responsibilities: [

    ],
    technologies: [

    ],
  },
  {
    title: 'NDA',
    description:
      'The first legal online cryptocurrency exchanger in the countries of the Customs Union.',
    position: 'Senior Front-End Engineer',
    timeActivity: 'July 2020 — June 2023',
    responsibilities: [
      'Lead Front-end developer',
      'Participate in design of technical solutions',
      'Performance and architecture improvements',
      'Code review',
      'Develop SDK App',
      'Mentoring, Plannings, estimations, tasks assigning and supervising',
    ],
    technologies: [
      'React', 'Redux', 'Redux Saga', 'Typescript', 'styled-components', 'ramda',
    ],
  },
  {
    title: 'NDA',
    description:
      'A project related to the sale of goods through vending machines. Administration, addition, retrieval of goods through the management of the vending machine.',
    position: 'Back-end developer',
    timeActivity: 'March 2020 - June 2020',
    responsibilities: [
      'Responsible for creating a fully back-end side of the app',
      'Participate in design of technical solutions',
      'Performance and architecture improvements',
      'Mentoring, Plannings, estimations, tasks assigning and supervising',
    ],
    technologies: [
      'Node.js', 'Apollo-GraphQL', 'Apollo-gateway', 'Mongoose', 'Mongo DB', 'Ramda', 'Google-Cloud',
    ],
  },
  {
    title: 'NDA',
    description:
      'Analysis and processing of medical information, exchange between doctors, patients and service personnel for mobile and web application\nValidation and presentation of data in graphical form, generation and uploading of reports',
    position: 'Front-End/Back-end Engineer',
    timeActivity: 'January 2019 - February 2020',
    responsibilities: [
      'Full-stack developer',
      'Mobile (iOS, Android) development',
      'Participate in design of technical solutions',
      'Performance and architecture improvements',
      'Code review',
      'Communication with customer',
      'Mentoring, Plannings, estimations, tasks assigning and supervising',
    ],
    technologies: [
      'React', 'React-native', 'Redux', 'Redux Saga', 'Typescript', 'styled-components', 'ramda',
    ],
  },
  {
    title: 'NDA',
    description:
      'Web application for managing micro-financial operations.',
    position: 'Front-end Developer',
    timeActivity: 'October 2018 - January 2019',
    responsibilities: [
      'Integration client side app',
      'UI/UX Implementation',
      'Writing unit tests',
    ],
    technologies: [
      'React', 'Redux', 'Redux Saga', 'Material UI',
    ],
  },
  {
    title: 'NDA',
    description:
      'Optical company',
    position: 'Back-end Developer',
    timeActivity: 'July 2017 - October 2018',
    responsibilities: [
      'Back-end development',
      'PDF Invoice generation',
      'Writing statistics API for stores sales',
      'Writing DB migrations',
    ],
    technologies: [
      'Node.js', 'Express.js', 'MongoDB', 'Mongoose', 'Swagger', 'Joi', 'Socket.io', 'Elastic search',
    ],
  },
  {
    title: 'NDA',
    description:
      'Emergency communication system in case of accident',
    position: 'Back-end Developer',
    timeActivity: 'June 2017',
    responsibilities: [
      'Back-end development',
      'Refactoring of application architecture',
      'Integration with third-party services',
    ],
    technologies: [
      'Node.js', 'Express.js', 'Loopback', 'MongoDB', 'Twilio',
    ],
  },
  {
    title: 'NDA',
    description:
      'Business management system',
    position: 'Front-end developer',
    timeActivity: 'April 2017 - June 2017',
    responsibilities: [
      'Web development',
      'Cross-browser development',
      'Writing unit tests',
    ],
    technologies: [
      'React', 'Redux', 'Redux Saga', 'Reselect', 'i18next', 'webpack',
    ],
  },
];
